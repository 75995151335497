import React from "react";

const Reset = () => (
  <style jsx global>{`
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
    @namespace svg url(http://www.w3.org/2000/svg);

    :root {
      --size-xxxxs: 1px; /* -1 */
      --size-xxxs: 2px; /* -1 */
      --size-xxs: 3px; /* -3 */
      --size-xs: 6px; /* -3 */
      --size-s: 12px; /* -6 */
      --size-m: 18px;
      --size-l: 24px; /* +6 */
      --size-xl: 36px; /* +12 */
      --size-xxl: 48px; /* +12 */
      --size-xxxl: 72px; /* +24 */
      --size-xxxxl: 96px; /* +24 */

      --color-black: #151310;
      --color-midnight: #191715;
      --color-dune: #1d1b19;
      --color-almond: #32302d;
      --color-schooner: #3d3b38;
      --color-wafer: #c8c6c3;
      --color-mercury: #e7e6e4;
      --color-ghost: #f5f3f0;
      --color-froth: #fafaf9;
      --color-white: #ffffff;
      --color-sunglow: #ffb40a;

      --system-font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      --brand-font-stack: Roboto, sans-serif;

      --font-title: normal 500 36px / var(--size-xxl) var(--brand-font-stack);
      --font-heading: normal 500 30px / var(--size-xl) var(--brand-font-stack);
      --font-subheading: normal 500 20px / var(--size-l) var(--brand-font-stack);
      --font-hero: normal 400 24px / var(--size-xl) var(--system-font-stack);
      --font-body: normal 400 16px / var(--size-l) var(--system-font-stack);
      --font-bold: normal 500 16px / var(--size-l) var(--system-font-stack);
      --font-italic: italic 400 16px / var(--size-l) var(--system-font-stack);
      --font-bold-italic: italic 500 16px / var(--size-l)
        var(--system-font-stack);
      --font-annotation: normal 500 12px / var(--size-m)
        var(--system-font-stack);
      --font-monospace: normal 500 16px / var(--size-l) monospace;
      --font-small: normal 400 14px / var(--size-m) var(--system-font-stack);
      --font-small-bold: normal 500 14px / var(--size-m)
        var(--system-font-stack);

      --column-width: var(--size-xxxxl);
      --gutter-width: var(--size-l);

      --background-color: var(--color-white);
      --text-color: var(--color-black);
      --muted-color: #ccc;
      --accent-color: var(--color-sunglow);
      --complementary-color: var(--color-black);
      --border-color: var(--color-mercury);

      --border-width: var(--size-xxxs);
      --radius-m: var(--size-xs);
    }

    @media (prefers-color-scheme: dark) {
      :root {
        --background-color: var(--color-black);
        --text-color: var(--color-white);
        --muted-color: var(--color-schooner);
        --accent-color: #fb0;
        --complementary-color: #333;
        --border-color: var(--color-schooner);
      }
    }

    :not(svg|*) {
      all: unset;
      box-sizing: border-box;
    }

    head,
    link,
    meta,
    noscript,
    script,
    style,
    title {
      display: none;
    }

    audio,
    canvas,
    iframe,
    img,
    math,
    object,
    svg,
    video {
      display: block;
    }

    body {
      color: var(--text-color);
      background: var(--background-color);
      font: var(--font-body);
      text-transform: none;
    }
  `}</style>
);

export default Reset;
