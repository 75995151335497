import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import useJitsu from "../hooks/useJitsu";

import Reset from "../components/Reset";

if (process.browser && process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID) {
  LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID);
  setupLogRocketReact(LogRocket);
}

const App = ({ Component, pageProps }) => {
  useJitsu({
    key: process.env.NEXT_PUBLIC_JITSU_KEY,
    tracking_host: process.env.NEXT_PUBLIC_JITSU_TRACKING_HOST,
    cookie_domain: process.env.NEXT_PUBLIC_JITSU_COOKIE_DOMAIN,
  });

  return (
    <>
      <Reset />
      <Component {...pageProps} />
    </>
  );
};

export default App;
