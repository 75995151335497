import { jitsuClient } from "@jitsu/sdk-js";
import { useEffect } from "react";
import { useRouter } from "next/router";

const useJitsu = ({ key, ...config }) => {
  if (!key) return;

  const router = useRouter();

  useEffect(() => {
    const jitsu = jitsuClient({ key, ...config });

    const handler = async () => {
      jitsu.track("app_page");
    };

    // https://github.com/vercel/next.js/blob/33255b7/examples/with-react-ga/pages/_app.js#L10-L15
    if (!router.asPath.includes("?")) {
      handler();
    }

    router.events.on("routeChangeComplete", handler);

    return () => {
      router.events.off("routeChangeComplete", handler);
    };
  }, [router]);
};

export default useJitsu;
